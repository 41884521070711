import React from 'react';
import { CompanyStripeSubscription } from "../../../models/company.model";
import { useHttpRequest } from "../../../hooks/use-http-request";
import { TextView } from "../../../components/TextView";
import { SimpleSpinner } from "../../../components/SimpleSpinner";

export function StripeSubscriptionIdField(props: Props) {
  const {stripeSubscription} = props;
  const stripeEntityUpdater = useHttpRequest(`/company-stripe`);

  const save = async (val: string) => {
    try {
      await stripeEntityUpdater.PUT({
        id: stripeSubscription.id, field: 'subscription_id', value: val
      })
      stripeSubscription.subscriptionId = val;
    } catch (e) {
      console.error(e);
    }
  }
  return (
    <div className={"updateableField__wrapper"}>
      <TextView label="Stripe Subscription ID"
                disabled={stripeEntityUpdater.loading}
                value={stripeSubscription?.subscriptionId ?? ''}
                onChange={() => {}}
                onBlur={save} />
      {stripeEntityUpdater.loading && <SimpleSpinner />}
    </div>
  )
}

interface Props {
  stripeSubscription: CompanyStripeSubscription;
}
