import React from 'react';
import { CompanyStripeSubscription, StripeState } from "../../../models/company.model";
import { useHttpRequest } from "../../../hooks/use-http-request";

export function StripeStateField(props: Props) {
  const {stripeSubscription} = props;
  const stripeEntityUpdater = useHttpRequest(`/company-stripe`);

  const onChange = async (val: any) => {
    try {
      await stripeEntityUpdater.PUT({
        id: stripeSubscription.id, field: 'state', value: val.target.value
      })
      stripeSubscription.state = val.target.value;
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <>
      <p className={"body__xsmall body--bold"}>State</p>

      <select name="stripe-state" id="stripe-state" value={stripeSubscription?.state} onChange={onChange}>
        <option value={undefined}></option>
        <option value={StripeState.active} selected={stripeSubscription?.state === StripeState.active}>{StripeState.active}</option>
        <option value={StripeState.open} selected={stripeSubscription?.state === StripeState.open}>{StripeState.open}</option>
        <option value={StripeState.paid} selected={stripeSubscription?.state === StripeState.paid}>{StripeState.paid}</option>
        <option value={StripeState.cancelled} selected={stripeSubscription?.state === StripeState.cancelled}>{StripeState.cancelled}</option>
      </select>
    </>
  )
}

interface Props {
  stripeSubscription: CompanyStripeSubscription;
}
