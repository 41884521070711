import React, { useEffect, useState } from 'react';
import { useHttpRequest } from "../../../hooks/use-http-request";
import { User } from "../../../models/user.model";

export function InvalidateUsersCalendarEvents(props: Props) {
  const request = useHttpRequest('/api/user/invalidate-calendar-events');
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    setLoading(true);
    try {
      await request.POST({userId: props.user.id});
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (request.postResponse && request.postResponse.numberInvalidated) {
      alert(`Invalidate ${request.postResponse.numberInvalidated} calendar events`);
    }
  }, [request.postResponse]);

  return (
    <>
      <button disabled={loading} className="button button--primary button--compact" style={{marginLeft: 16, marginBottom: 32}} onClick={onClick}>
        <span style={{color: 'white'}}>Invalidate calendar events</span>
      </button>
    </>
  )
}

interface Props {
  user: User
}
