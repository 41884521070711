export interface Company {
  id: string;
  name: string;
  numberOfFreeUsers: number;
  numberOfPaidUsers: number;

  active: boolean;
  billTotal: number;
  freeTrial: boolean;
  freeTrialExpiryDate: string;
  paymentFrequency: string;
  stripeQuantity: number;

  domainSignupEnabled: boolean;
  domainSignupPending: boolean;
  domainName: string;
}

export interface CompanyEntity {
  id: string;
  name: string;
  enabled: boolean;
  freeUsers: number;
  paidForUsers: number;
  meetingRoomLimit: number;
  numberOfTeamsLimit: number;
  enableHolidayBooking: boolean;
  deskBookingEnabled: boolean;
  lineManagementEnabled: boolean;
  sevenDayWhereaboutsWeekEnabled: boolean;
  whereaboutsApprovalsEnabled: boolean;
  domainNameSignupsEnabled: boolean;
  domainNameSignupPending: boolean;
  holidayApprovalEnabled: boolean;
  calendarSyncEnabled: boolean;
  domainName: string;
  logoUrl?: string;
  holidayApprovalNotificationsEnabled: boolean;
  tenantBasedAuthEnabled?: boolean;
  weeklyNotesEnabled?: boolean;
  disableEditingPreviousWhereabouts: boolean;
  officeAttendanceConfirmationEnabled: boolean;
  config?: PlatformConfigEntity;
  renewalDate?: string;
  paymentType?: string;
}

export interface PlatformConfigEntity {
  holidayAllowance: boolean;
  numberOfDesksAvailable: number;
  companyHolidayAllowance: number;
  holidayYearRecurringMonth: number;
  holidayYearRecurringDay: number;
  holidayYearEnd?: string;
  holidayYearStart?: string;
  carParkingEnabled?: boolean;
}


export interface Team {
  name: string;
  id: string;
  companyId: string;
}

export interface Office {
  id: number;
  companyId: string;
  postCode: string;
  label: string;
  capacity: number;
  parentId: number;
  deskBookingEnabled: boolean;
  officePlan: string;
  isOffice: boolean;
  isFloor: boolean;
  isArea: boolean;
  hasAreas: boolean;
  floorPlanEnabled: boolean;
  order: number;
  active: boolean;
}

export enum StripeState {
  paid = 'paid',
  open = 'open',
  active = 'active',
  cancelled = 'cancelled',
}

export interface CompanyStripeSubscription {
  id: number;
  customerId: string;
  userId: string;
  companyId: string;
  state?: StripeState;
  quantity: number;
  subscriptionId: string;
  currentPeriodEnd: string;
  trialExpiryDate: string;
  isActive: boolean;
  billTotal: number;
  dueDate: string;
  cardExpiryDate: string;
  lastFour: string;
  checkoutSession: string;
  isFreeTrial: boolean;
  paymentFrequency: string;
  usesStripe: boolean;
}
